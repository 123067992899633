<template>
   <!-- Hero footer: will stick at the bottom -->
   <div class="hero-foot">
      <nav class="tabs is-boxed is-fullwidth is-primary">
         <div class="container">
            <ul>
               <li class="is-active"><a>Рассказать Друзьям</a></li>
               <li @click="renderModal"><a>Баня для Мозгов</a></li>
               <li><a>Карта развития</a></li>
               <li><a href="https://books.tigrisco.com/contacts/" target="_blank">Написать нам</a></li>
               <li><a href="https://secure.wayforpay.com/donate/incomeformula" target="_blank">Поддержать донатом</a></li>
            </ul>
         </div>
      </nav>
   </div>
</template>

<script>
export default {
   methods: {
      renderModal() {
         console.log('work')
         this.$emit('modal')
      }
   }
}
</script>