<template>
   
   <div class="block has-text-centered" v-if="isActive">
      <h1 class="title">Твой Ежемесячный Доход: {{ renderSummary() }} <span class="is-size-5">(валюта твоей страны)</span></h1>
      <!--<h2 class="subtitle mt-3">Намного больше финансовых упражнений ты найдешь в серии книг «Баня для Мозгов». <br />Хочешь узнать больше о книгах, которые сделают Твой Год Успешным?</h2>-->
      <h2 class="subtitle mt-3">
         Внимательно изучи <strong>таблицу с результатами</strong>. Определи 2-3 наиболее слабые сферы в <strong>твоей Формуле Дохода</strong>. Это первые шаги для действий: те <strong>20% усилий</strong>, которые дадут <strong>80% результата</strong> в росте твоих доходов! В <strong>Рекомендациях</strong> ты найдешь советы и практики для эффективного старта.
      </h2>

      <h2 class="subtitle mt-3">
         <strong>Упражение было полезным?</strong> Буду Благодарен за твой исходящий поток! <a class="has-text-primary has-text-weight-semibold" href="https://secure.wayforpay.com/donate/incomeformula" target="_blank">Поддержи развитие проекта на любую сумму!</a> 🙏🏻 
      </h2>

      <p class="mb-3">Что делать если сумма слишком отличается от реальной или от той,которую ты хотел бы получать ежемесячно? <router-link to="/tips" class="has-text-primary">Посмотреть Советы</router-link></p>

      <div class="table-container">
         <table class="table is-striped is-hoverable is-fullwidth is-bordered ">
            <thead>
               <tr>
                  <th>Номер</th>
                  <th>Сфера</th>
                  <th>Направление</th>
                  <th>Важность</th>
                  <th>Твои Баллы</th>
                  <th>Зона для Роста</th>
                  <th>Рекомендации</th>
               </tr>
            </thead>
            <tbody>

               <tr v-for="item in formulaDB" :key="item.id">
                  <th>{{ item.id }}</th>
                  <td><strong>{{ item.type }}</strong></td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.maxScore }}</td>
                  <td>{{ item.result }}</td>
                  <td
                     class="has-text-white "
                     :class="{
                        'has-background-danger-light has-text-danger': (item.result / item.maxScore) < 0.3,
                        'has-background-warning-light has-text-warning-dark': (item.result / item.maxScore) < 0.6,
                        'has-background-link-light has-text-link': (item.result / item.maxScore) <= 0.8,
                        'has-background-primary-light has-text-primary': (item.result / item.maxScore) > 0.8
                     }"
                  >
                     {{ checkRating(item.result / item.maxScore) }}
                  </td>
                  <td>
                     <button
                        class="button is-small is-primary"
                        @click="modal('bath1')"
                     >Смотреть</button>
                  </td>
               </tr>

            </tbody>
         </table>
      </div>
   </div>
   
   <div class="block has-text-centered" v-else>
      <h2 class="title is-size-4">Ты еще не прошел диагностику «Формулы Дохода». Результатов пока нет...</h2>
      <button class="button is-large is-primary has-text-weight-semibold is-uppercase mt-3" @click="$router.push('/start')">
            <span>Запустить Тест</span>
            <span class="icon is-medium">
               <svg-icon type="mdi" :path="path.rocket" :size="48"></svg-icon>
            </span>
         </button>
   </div>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon'
import { mdiRocketLaunchOutline } from '@mdi/js'

export default {
   inject: ['formulaDB', 'renderSummary', 'modal'],
   data() {
      return {
         isActive: this.formulaDB[14].result,
         path: {
            rocket: mdiRocketLaunchOutline
         }
      }
   },
   methods: {
      checkRating(rating) {
         if (rating < 0.3) {
            return 'Критически мало'
         } else if (rating < 0.6) {
            return 'Важно улучшить'
         } else if (rating <= 0.8) {
            return 'Хороший Уровень'
         } else {
            return 'Отличный Результат'
         }   
      },
      getResult() {
         this.renderSummary()
      }
   },
   mounted() {
      document.title = 'Результаты | TigrisCo'
   },
   components: {
      SvgIcon
   }
}
</script>

<style scoped>
</style>