import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
// import LazyTube from "vue-lazytube"

//import SvgIcon from '@jamescoyle/vue-icon'
//import { mdiAccount } from '@mdi/js'

import './assets/styles/main.scss'

createApp(App)
   .use(router)
   .mount('#app')
