<template>
   <h1 class="title">Поблагодарить и поддержать проект</h1>
   <p class="is-size-4">Дорогой друг! Я искренне надеюсь, что Диагностика Личных Финансов была для тебя полезной и ценной! Буду очень БЛАГОДАРЕН ТЕБЕ за поддержку проекта на любую сумму. Ты можешь сам определить размер поддержки за ту ценность, что получил выполняя упражнение и рекоммендации «Формулы Дохода»!</p>
   <p class="is-size-4 pt-4">Твой Исходящий Поток очень важен для меня, он позволяет оплачивать работу проекта и вдохновляет на разработку новых полезных приложений и сервисов. 🙏🏻🙏🏻🙏🏻 Желаю тебе всего наилучшего!</p>
   <p class="is-size-5 pt-4">Serhii GerkHard</p>

   <a class="button is-primary is-large mt-5" href="https://secure.wayforpay.com/donate/incomeformula" target="_blank">Поблагодарить и Поддержать</a>
</template>


<script>
export default {
   mounted() {
      document.title = 'Донейшен | TigrisCo'
   }
}
</script>