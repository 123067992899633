<template>
   <div class="modal-content has-text-centered">
      <figure class="image is-16by9">
         <YoutubeVue3 class="has-ratio" ref="youtube" videoid="aHP_tC6C0jA" :autoplay="1" />
      </figure>

      <a class="button is-primary is-large mt-5" href="https://books.tigrisco.com/" target="_blank">Смотреть книги</a>
   </div>
</template>

<script>
import { YoutubeVue3 } from 'youtube-vue3'

export default {
   mounted() {
      this.$refs.youtube.player.playVideo()
   },
   components: {
      YoutubeVue3
   }
}
</script>