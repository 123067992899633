<template>
   <section class="hero is-fullheight">
      <TheNavbar @modal="modal('author')" />

      <div class="hero-body">
         <div class="container">
            <router-view></router-view>
         </div>
      </div>

      <TheFooter @modal="modal('author')" />

      <AppModal
         v-if="modalVisible"
         :type="modalType"
      />
   </section>
</template>

<script>
import TheNavbar from './components/TheNavbar'
import TheFooter from './components/TheFooter'
import AppModal from './components/AppModal'

import formulaDB from './assets/formulaDB'

export default {
   name: 'App',
   provide() {
      return {
         formulaDB,
         renderResult: this.renderResult,
         modal: this.modal,
         changeModalVisibility: this.changeModalVisibility,
         renderSummary: this.renderSummary
      }
   },
   data() {
      return {
         modalVisible: false,
         modalType: '',
         formula: [],
         summary: 1
      }
   },
   methods: {
      renderResult(result) {
         console.log('myResult', result);
         formulaDB.forEach((item, idx) => {
            item.result = result[idx]
         })

         for (let idx = 0; idx < result.length; idx += 3) {
            let sum = (result[idx] + result[idx + 1] + result[idx + 2]) / 3
            this.formula.push(Math.round((sum + Number.EPSILON) * 100) / 100)
         }

         console.log('formula', this.formula)

         this.formula.forEach(item => {
            this.summary = this.summary * item
         })

         console.log('summary', this.summary)
      },
      changeModalVisibility() {
         this.modalVisible = !this.modalVisible
      },
      renderSummary() {
         return Math.round(this.summary)
      },
      
      modal(type) {
         this.modalType = type
         this.modalVisible = true
      }
   },
  computed: {

  },
   components: {
      TheNavbar, TheFooter, AppModal
   }
}
</script>

<style lang="scss">

</style>