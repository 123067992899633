<template>
   <div class="section columns is-vcentered">
      <div class="column is-6">
         <h1 class="title is-size-1 is-uppercase has-text-weight-bold has-text-primary">Формула Дохода</h1>
         <h2 class="subtitle is-size-4 is-uppercase mb-1">Диагностический тест твоих доходов</h2>
         <div class="mb-5 is-uppercase">** Проект на основе методик из книг Валерия Стрильчика</div>
         <div class="content is-medium">
            <div class="icon-text">
               <span class="icon has-text-link">
                  <svg-icon class="is-primary " type="mdi" :path="path.grow" :size="48"></svg-icon>
               </span>
               <span>Узнай наиболее важные зоны для роста доходов</span>
            </div>
            <div class="icon-text pt-2">
               <span class="icon has-text-link">
                  <svg-icon type="mdi" :path="path.cash"></svg-icon>
               </span>
               <span>Получи стартовый план финансового благополучия</span>
            </div>
            <div class="icon-text pt-2">
               <span class="icon has-text-link">
                  <svg-icon type="mdi" :path="path.book"></svg-icon>
               </span>
               <span>Узнай о книге, которая сделает твой год успешным</span>
            </div>
         </div>
         <router-link custom to="/start" v-slot="{ navigate }">
            <button class="button is-large is-primary has-text-weight-semibold is-uppercase mt-3" @click="navigate">
               <span>Запустить Тест</span>
               <span class="icon is-medium">
                  <svg-icon type="mdi" :path="path.rocket" :size="48"></svg-icon>
               </span>
            </button>
         </router-link>
         
      </div>

      <div class="column has-text-centered">
         <img src="../assets/images/man-hero.svg" alt="Формула Дохода">
      </div>
   </div>
   


   <article class="section">
      <h2 class="title has-text-centered">С чего начать? Пошаговый Алгоритм</h2>
      <div class="columns is-vcentered pt-6">
         <div class="column">
            <div class="box step">
               <figure class="image">
                  <img class="step__icon" src="../assets/icons/instruction.svg" alt="Инструкция">
               </figure>
               <h3 class="title is-4 pt-5">Инструкция</h3>
               <p>Изучите краткую видеоинструкцию, чтобы эффективно провести финансовую диагностику ваших ежемесячных доходов и правильно рассчитать вашу Ежемесячную Формулу Дохода</p>
            </div>
         </div>
         <div class="column is-1">
            <figure class="image is-64x64 m-auto">
               <img src="../assets/icons/arrow.svg" alt="Следующий этап">
            </figure>
         </div>
         <div class="column">
            <div class="box step">
               <figure class="image">
                  <img class="step__icon" src="../assets/icons/test.svg" alt="Финансовая Диагностика">
               </figure>
               <h3 class="title is-4 pt-5">Финансовая Диагностика</h3>
               <p>Внимательно ответьте на все вопросы по Финансовой Диагностике. Постарайтесь оценить свой уровень максимально объективно и конкретно в каждой области Финансовой Формулы</p>
            </div>
         </div>
         <div class="column is-1">
            <figure class="image is-64x64 m-auto">
               <img src="../assets/icons/arrow.svg" alt="Следующий этап">
            </figure>
         </div>
         <div class="column">
            <div class="box step">
               <figure class="image">
                  <img class="step__icon" src="../assets/icons/results.svg" alt="Результаты">
               </figure>
               <h3 class="title is-4 pt-5">Проработка Результатов</h3>
               <p>Оцените и проработайте ваши результаты. Сравните вашу Формулу Дохода с вашими реальными Ежемесячными Доходами. В случае необходимости произведите калибровку</p>
            </div>
         </div>
      </div>
   </article>



   <article class="section columns is-vcentered mt-6">
      <div class="column has-text-centered">
         <img src="../assets/images/man-action.svg" alt="Формула Дохода">
      </div>
      <div class="column">
         <h2 class="title">Маленькие Шаги к Большим Деньгам</h2>
         <h3 class="subtitle is-size-4">Подробную технику диагностики «Формула Дохода» и много других финансовых практик ты найдешь в серии книг Валерия Стрильчика «Баня для Мозгов»</h3>
         <p class="is-size-5">Создай успешный год и получи первые результаты уже во время чтения книг на основе тренинга «Школа Денег». Твой пошаговый путь к эффективности, финансовой свободе и счастливой жизни!</p>
         <div class="buttons pt-5">
            <a class="button is-medium is-primary has-text-weight-medium is-uppercase mr-5" href="https://books.tigrisco.com/" target="_blank">
               <span>Смотреть Книги</span>
               <span class="icon is-medium">
                  <svg-icon type="mdi" :path="path.book" :size="48"></svg-icon>
               </span>
            </a>
            <button class="button is-medium is-link has-text-weight-medium is-uppercase" @click="modal('author')">
               <span>Обзор от Автора</span>
               <span class="icon is-medium">
                  <svg-icon type="mdi" :path="path.video" :size="48"></svg-icon>
               </span>
            </button>
         </div>
      </div>
   </article>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon'
import { mdiBookOpenPageVariant, mdiCashMultiple, mdiRocketLaunchOutline, mdiFitToPageOutline, mdiBookSearchOutline, mdiVideoPlusOutline } from '@mdi/js'

export default {
   inject: ['modal'],
   data () {
      return {
         path: {
            rocket: mdiRocketLaunchOutline,
            book: mdiBookOpenPageVariant,
            cash: mdiCashMultiple,
            grow: mdiFitToPageOutline,
            book: mdiBookSearchOutline,
            video: mdiVideoPlusOutline
         }
         
      }
   },
   mounted() {
      document.title = 'Формула Дохода | TigrisCo'
   },
   components: {
      SvgIcon
   }
}
</script>

<style lang="scss" scoped>
.icon-text .icon:not(:last-child) {
   margin-right: 0.5em;
}

img {
   max-height: 500px;
}

.column {
   .step {
      padding-top: 40px;
      padding-bottom: 50px;
   }
   &:nth-child(1) {
      .step {
         border-top: 10px solid #582EFF;
      }
   }
   &:nth-child(3) {
      .step {
         border-top: 10px solid #FF814A;
      }
   }
   &:nth-child(5) {
      .step {
         border-top: 10px solid #31C5A1;
      }
   }

   .step__icon {
      height: 75px;
      width: auto;
   }
}
</style>