<template>
   <article class="section">
      <h2 class="title has-text-centered">Что если сумма слишком отличается от реальной?</h2>
      <div class="columns is-vcentered pt-6">
         <div class="column">
            <div class="box step">
               <figure class="image">
                  <img class="step__icon" src="../assets/icons/instruction.svg" alt="Инструкция">
               </figure>
               <h3 class="title is-4 pt-5">ШАГ 1: Точность Формулы Дохода</h3>
               <p>Вы получите настолько же точное значение своего Ежемесячного Дохода, насколько точными будут ваши оценки исходных параметров! Если значение рассчитанного Дохода не совпадает с тем, что вы реально имеете, значит вы ввели неправильные данные: «Самооценку» завысили или «Развитие» неправильно просчитали. Пожалуйста, не торопитесь с выводами о «неправильности» Формулы Дохода. Переходите к следующему пункту!</p>
            </div>
         </div>
         <div class="column is-1">
            <figure class="image is-64x64 m-auto">
               <img src="../assets/icons/arrow.svg" alt="Следующий этап">
            </figure>
         </div>
         <div class="column">
            <div class="box step">
               <figure class="image">
                  <img class="step__icon" src="../assets/icons/test.svg" alt="Финансовая Диагностика">
               </figure>
               <h3 class="title is-4 pt-5">ШАГ 2: Проверьте введенные данные</h3>
               <p> Если у вас доход больше реального, посмотрите, где вы завысили данные. Скорее всего, вы переоценили параметр «Развитие» (в этой области доступно сразу 220 баллов), и не так уж много людей в стране и мире знают о вашем бизнесе или о вашем товаре, как вы об этом думаете. А может вы поставили себе слишком большой балл в параметре «Самооценка». Или в начале не учли параметр «Профессионализм». А еще, скорее всего, неточности и там и там...</p>
            </div>
         </div>
         <div class="column is-1">
            <figure class="image is-64x64 m-auto">
               <img src="../assets/icons/arrow.svg" alt="Следующий этап">
            </figure>
         </div>
         <div class="column">
            <div class="box step">
               <figure class="image">
                  <img class="step__icon" src="../assets/icons/results.svg" alt="Результаты">
               </figure>
               <h3 class="title is-4 pt-5">ШАГ 3: Откалибруйте Полученные Результаты</h3>
               <p>Подберите исходные данные так, чтобы произведение всех параметров давало сумму дохода, которую вы реально получаете ежемесячно. Зафиксируйте эти данные, возьмите их за основу. А затем определите, над какими из параметров необходимо поработать, чтобы итоговая цифра увеличилась. Наше приложение наиболее критичные конкретно для вас области роста подсветит красным и желтым цветом. Надеемся данная техника была для вас полезной!</p>
            </div>
         </div>
      </div>
   </article>
</template>


<script>
export default {
   mounted() {
      document.title = 'Рекомендации | TigrisCo'
   }
}
</script>



<style lang="scss" scoped>
.column {
   .step {
      padding-top: 40px;
      padding-bottom: 50px;
   }
   &:nth-child(1) {
      .step {
         border-top: 10px solid #582EFF;
      }
   }
   &:nth-child(3) {
      .step {
         border-top: 10px solid #FF814A;
      }
   }
   &:nth-child(5) {
      .step {
         border-top: 10px solid #31C5A1;
      }
   }

   .step__icon {
      height: 55px;
      width: auto;
   }
}
</style>