<template>
   <div class="modal-card">
      <header class="modal-card-head">
         <p class="modal-card-title">Книга «Баня для Мозгов 1, или смотри на жизнь сквозь пальцы»</p>
      </header>
      <section class="modal-card-body">
        <div class="columns is-vcentered">
           <div class="column has-text-centered">
              <img src="../../assets/images/bath_for_brain_1.png" alt="Баня для Мозгов 1">
           </div>
           <div class="column">
              <div class="content">
                  <p>Профессионализм, Энергопотенциал</p>
                  <p>Книга «Баня для Мозгов 1, или смотри на жизнь сквозь пальцы» поможет тебе разобраться в самом себе, найти свое предназначение и дело по душе.</p>
                  <p>В книге ты встретишь практические упражнения по поиску своего предназначения, </p>
                  <p>Путешествие, соединяющее в ЕДИНОЕ ЦЕЛОЕ вещи и явления, казалось бы, совершенно несоединимые: деньги и духовность, бизнес и человеческие чувства, богатство и мышление, здоровье и успех, квантовую физику и семейные отношения.</p>
              </div>
           </div>
        </div>
      </section>
      <footer class="modal-card-foot">
         <button class="button is-success">Save changes</button>
         <button class="button">Cancel</button>
      </footer>
   </div>
</template>

<script>
   export default {

   }
</script>

<style lang="scss" scoped>
img {
   max-height: 400px;
}
</style>