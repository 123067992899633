export default [
   {
      id: 1,
      type: 'Профессионализм',
      name: 'Дело по Душе',
      title: 'Есть ли у тебя ДЕЛО ПО ДУШЕ?',
      subTitle: 'Оцени от 1 до 10: если НЕТ - ставь 1 балл, если ДА - 10 баллов',
      maxScore: 10,
      img: 'hobby',
      description: 'Если Дела по Душе нет и ты никогда об этом даже не думал(а) – ставь 1 балл. А может Дело по душе есть, но ты им пока не занимаешься, а занимаешься тем, что не по душе и без радости - 4 бала. Дело есть, ты им занимаешься и зарабатываешь на нем дополнительный доход - 8 балов. Дело по душе есть и ты уже хорошо зарабатываешь на нем, стал(а) признанным экспертом в своей области - тогда это 10 балов!'
   },
   {
      id: 2,
      type: 'Профессионализм',
      name: 'Твой Профессионализм',
      title: 'Насколько ты профессионал в том деле, которое делаешь в настоящее время?',
      subTitle: 'Оцени от 1 до 10: если НЕТ - ставь 1 балл, если ДА - 10 баллов',
      maxScore: 10,
      img: 'professional',
      description: 'Если ты – полный лопух, ничего не смыслишь в том, что делаешь – ставь себе 1 балл. Если ты – самый крутой профи в этой отрасли, круче тебя просто быть не может, и всё равно ты регулярно ездишь на семинары, повышаешь свой уровень, и тебя знают в отрасли все твои коллеги – ставь себе 10 баллов!'
   },
   {
      id: 3,
      type: 'Профессионализм',
      name: 'Наставник',
      title: 'Есть ли у тебя наставник? Насколько он профессионал?',
      subTitle: 'Оцени от 1 до 10: если НЕТ - ставь 1 балл, если ДА - 10 баллов',
      maxScore: 10,
      img: 'mentor',
      description: 'Если наставника нет или твой наставник – это злобные бабушки у подъезда и для тебя то, что они скажут, становится самым важным в жизни – ставь 1 балл. Если наставник есть, он самый крутой профессионал в отрасли, он с тобой не цацкается, не жалеет, наоборот, он жёсткий и требовательный держит тебя постоянно в тонусе. Если ты с ним регулярно консультируешься и выполняешь все его рекомендации – ставь себе 10 баллов!'
   },
   {
      id: 4,
      type: 'Энергопотенциал',
      name: 'Продуктивность',
      title: 'Можешь ли ты в своём деле выложиться на все 100 процентов? А на 101? Насколько ты обычно выкладываешься? Сколько СЕБЯ ты вкладываешь в то дело, в котором хочешь быть профессионалом?',
      subTitle: 'Оцени от 1 до 10: если НЕТ - ставь 1 балл, если ДА - 10 баллов',
      maxScore: 10,
      img: '101-percent',
      description: 'Если вообще забыл(а), что такое выкладываться, а так, передвигаешься на работе, трусишь своим уставшим телом, как холодец, от звонка до обеда – ставь 1 балл. Если работу обожаешь, отдаёшься ей, день пролетает, как мгновение, уходить домой не хочется, люди вокруг тебя заряжаются от тебя, сияют, как лампочки, и тоже домой не идут – тогда это 10 баллов!'
   },
   {
      id: 5,
      type: 'Энергопотенциал',
      name: 'Жизнестойкость',
      title: 'Как часто ты устаёшь и насколько быстро ты восстанавливаешь силы?',
      subTitle: 'Оцени от 1 до 10: если НЕТ - ставь 1 балл, если ДА - 10 баллов',
      maxScore: 10,
      img: 'energy',
      description: 'Если каждый день просыпаешься с усталостью, если жизнь уже который год не в радость, если соседский кот у тебя «топочет, как слон, по подоконнику», если тебя на неделю выбивают вечерние новости – 1 балл. Если ты мгновенно можешь восстановить спокойствие и войти в медитацию, даже оказавшись на сотом этаже небоскрёба посреди землетрясения. Если ты приводишь себя в полную боевую готовность после пары часов сна. Если по желанию можешь мгновенно мобилизовать тело, а затем за минуту так же, успокоить его и расслабить – 10!'
   },
   {
      id: 6,
      type: 'Энергопотенциал',
      name: 'Физическая подготовка',
      title: 'Занимаешься ли ты регулярно физической подготовкой?',
      subTitle: 'Оцени от 1 до 10: если НЕТ - ставь 1 балл, если ДА - 10 баллов',
      maxScore: 10,
      img: 'sport',
      description: 'Если последний раз зарядку делал(а) в третьем классе и то потому, что не удалось с урока физкультуры сбежать. Если для тебя до туалета пешком дойти – проблема, и ты такси вызываешь – ставь 1 балл. Если раз или два в неделю у тебя тренировка, наполняющая тело жизнью и радостью, если раза два в год у тебя экстрим– тренинг по полной программе, нагрузку телу и мозгам дающий, чтоб аж дух захватывало – ставь 10 баллов, ты заслужил(а) их!'
   },
   {
      id: 7,
      type: 'Развитие',
      name: 'Востребованность дела',
      title: 'Сколько человек знает о том деле, которым ты занимаешься, о той компании, где ты работаешь, или о том товаре, который ты продаёшь?',
      subTitle: 'Оцени от 1 до 100: если НЕТ - ставь 1 балл, если ДА - 100 баллов',
      maxScore: 100,
      img: 'popularity',
      description: 'Если ты только вчера воз с места сдвинул(а), если о компании твоей никто не слышал, ты сам(а) только вчера сел(а) её устав писать, если о твоём деле даже ты толком не знаешь, потому что только вчера тебя идея гениальная посетила – ставь 1 балл. Если деятельность твоя всеми людьми востребована, если компания твоя у всех на устах гремит, если за товаром или услугой люди очереди занимают и друг другу по ночам восторгами делятся, ставь 100 баллов!'
   },
   {
      id: 8,
      type: 'Развитие',
      name: 'Известность личности',
      title: 'Насколько ты известный людям как самый лучший в своём деле?',
      subTitle: 'Оцени от 1 до 20: если НЕТ - ставь 1 балл, если ДА - 20 баллов',
      maxScore: 20,
      img: 'speaker-person',
      description: 'Если о твоих талантах даже ты не догадываешься, если о тебе знает только папа и мама, и то только то, что ты плохо в школе учился(ась) – выбирай 1 балл. Если твоё имя у всех на слуху, если каждый при встрече с тобой автограф просит, если люди считают за честь с тобой встретиться и от тебя лично доброе слово услышать, – пиши тогда себе 20 баллов!'
   },
   {
      id: 9,
      type: 'Развитие',
      name: 'Рост бизнеса',
      title: 'Что ты делаешь, чтобы развить свой бизнес?',
      subTitle: 'Оцени от 1 до 100: если НЕТ - ставь 1 балл, если ДА - 100 баллов',
      maxScore: 100,
      img: 'business',
      description: 'Если ты не знаешь даже то, что такое развитие, если ничего не делаешь, чтобы о твоём деле узнавали новые люди – ставь себе 1 балл. Если ты каждый день задаёшь себе вопрос: «Что я ещё могу сделать для развития дела?» Если еженедельно представляешь своё дело новым людям, а они другим людям об этом рассказывают. Если ты своё дело в бизнес-систему превратил, и оно развивается даже там, где ты никогда не был, и даже тогда, когда ты спишь, – тогда смело ставь себе 100 баллов.'
   },
   {
      id: 10,
      type: 'Самооценка',
      name: 'Личная самооценка',
      title: 'Любишь ли ты себя? Знаешь ли ты о своих способностях и талантах? Знаешь ли ты о своей уникальности и неповторимости?',
      subTitle: 'Оцени от 1 до 10: если НЕТ - ставь 1 балл, если ДА - 10 баллов',
      maxScore: 10,
      img: 'self-esteem',
      description: 'Если ты абсолютно уверен(а) в том, что ты неудачник(ца), ошибка природы и ничтожество – ставь 1 балл. Если ты знаешь, что ты – совершенство, что ты – это лучшее из того, что смогла за миллиарды лет эволюции создать Жизнь, что ты являешься невероятно значимым человеком как для Жизни, так и для тысяч, а может, и миллионов людей, живущих вместе с тобой на этой планете, – ставь тогда 10 баллов.'
   },
   {
      id: 11,
      type: 'Самооценка',
      name: 'Влияние роста',
      title: 'Умеешь ли ты подавать себя? Умеешь ли ты так себя вести, что присутствующим рядом людям хочется подарок тебе сделать?',
      subTitle: 'Оцени от 1 до 10: если НЕТ - ставь 1 балл, если ДА - 10 баллов',
      maxScore: 10,
      img: 'grow',
      description: 'Если ты только посмотрел на человека – и ему сразу хочется от тебя убежать подальше и больше никогда с тобой не встречаться – ставь 1 балл. Если просто само твоё присутствие наполняет людей покоем и радостью, если любое твоё слово, жест, выражение лица привлекает внимание людей, греет их, даёт им почувствовать свою значимость; если после встречи с тобой каждый человек чувствует себя лучше, достойнее, счастливее; если он хочет бежать и рассказывать другим о том, что он встретил тебя, – ставь тогда себе 10 баллов.'
   },
   {
      id: 12,
      type: 'Самооценка',
      name: 'Умение продавать',
      title: 'Умеешь ли ты продать людям то дело, которым ты занимаешься? Умеешь ли ты показать людям, насколько нужен им твой товар или твоя услуга?',
      subTitle: 'Оцени от 1 до 10: если НЕТ - ставь 1 балл, если ДА - 10 баллов',
      maxScore: 10,
      img: 'sale',
      description: 'Если ты боишься даже предложить им свой товар, если тебе даже стыдно признаться, чем ты занимаешься, – ставь 1 балл. Если после встречи с тобой чукчи начинают покупать у тебя снег рядом со своим чумом, а арабы начинают покупать песок; если после разговора с тобой люди просыпаются и первым делом с утра думают о тебе, о твоём товаре или о твоей услуге, – ставь тогда себе 10 баллов.'
   },
   {
      id: 13,
      type: 'Идеология',
      name: 'Мечта',
      title: 'Есть ли у тебя ЦЕЛЬ ЖИЗНИ, осознание твоей МИССИИ на планете, ВИДЕНИЕ того, как стать счастливой и реализованной личностью?',
      subTitle: 'Оцени от 1 до 10: если НЕТ - ставь 1 балл, если ДА - 10 баллов',
      maxScore: 10,
      img: 'dream',
      description: 'Если ты абсолютно уверен в том, что попал в эту жизнь по ошибке; если знаешь, что цель жизни – это делать нудную, ненавистную работу, а потом умереть в злобе и бедности – ставь 1 балл. Если ты знаешь, что твоё предназначение – БЫТЬ СЧАСТЛИВЫМ, если знаешь о своём ВЕЛИКОМ ТАЛАНТЕ, если составил КАРТИНУ СВОЕЙ ЖИЗНИ, если ты ВИДИШЬ – и тебя воодушевляет то, ЧТО ОСТАНЕТСЯ ПОСЛЕ ТЕБЯ; если у тебя есть ПЛАН; если ты УЖЕ СЕЙЧАС занимаешься всем этим и ежедневно свои идеи воплощаешь, – ставь себе тогда 10 баллов.'
   },
   {
      id: 14,
      type: 'Идеология',
      name: 'Дело',
      title: 'Есть ли у тебя ВИДЕНИЕ, какие ЦЕЛИ у твоего дела? Есть ли ПОНИМАНИЕ того, какую пользу твоё ДЕЛО принесёт другим людям? Есть ли у тебя ВЕРА в то, что ничто не собьёт тебя с выбранного пути?',
      subTitle: 'Оцени от 1 до 10: если НЕТ - ставь 1 балл, если ДА - 10 баллов',
      maxScore: 10,
      img: 'plan',
      description: 'Если ты даже не представляешь, что нужно делать или представляешь, что нужно, но не собираешься ничего делать – ставь 1 балл. Если у тебя есть ЛЕГЕНДА о твоём деле; если у тебя всё ЗАПИСАНО на бумаге и это воодушевляет тебя; если твоя Легенда других людей пробуждает, если она воодушевляет и к Делу твоему их влечёт; если они знают, с чего начать Дело и что будет его результатом, – ставь 10 баллов'
   },
   {
      id: 15,
      type: 'Идеология',
      name: 'Команда',
      title: 'Есть ли у тебя КОМАНДА? Насколько ПРОФЕССИОНАЛЬНА твоя команда? Насколько они ЛИДЕРЫ? Насколько они ЛИЧНОСТИ? Насколько прочны их ХАРАКТЕРЫ? Насколько они способны действовать ВМЕСТЕ?',
      subTitle: 'Оцени от 1 до 10: если НЕТ - ставь 1 балл, если ДА - 10 баллов',
      maxScore: 10,
      img: 'team',
      description: 'Если ты даже сам(а) ещё на Дело не решился(ась), если ты ничего не делаешь – ставь тогда себе 1 балл. Если у тебя есть КОМАНДА друзей, которые горят общей с тобой ИДЕЕЙ, если они ЛЕГЕНДУ о Деле Великом вместе с тобой создали, если они сами ДЕЙСТВУЮТ и людей за собой увлекают, а их ФОРМУЛА ДОХОДА зашкаливает на максимуме – ставь тогда себе 10 баллов.'
   },
];