<template>
   <!-- Hero head: will stick at the top -->
   <div class="hero-head">
      <header class="navbar is-primary has-shadow">
         <div class="container">
            <div class="navbar-brand">
               <a href="/" class="navbar-item">
                  <img src="../assets/images/tigrisco-logo.svg" alt="Логотип TigrisCo">
               </a>
               <span
                  class="navbar-burger"
                  :class="{'is-active': isActive}"
                  @click="isActive = !isActive"
               >
                  <span></span>
                  <span></span>
                  <span></span>
               </span>
            </div>
            <div
               class="navbar-menu"
               :class="{'is-active': isActive}"
            >
               <div class="navbar-end is-uppercase has-text-weight-medium">
                  <router-link to="/" class="navbar-item">
                     Главная
                  </router-link>
                  <router-link to="/start" class="navbar-item">
                     Формула Дохода
                  </router-link>
                  <router-link to="/result" class="navbar-item">
                     Результаты
                  </router-link>
                  <router-link to="/tips" class="navbar-item">
                     Рекомендации
                  </router-link>
                  <span class="navbar-item">
                     <router-link to="/gratitude" class="button is-white is-outlined is-uppercase">
                        <span class="icon">
                           <svg-icon type="mdi" :path="path.charity"></svg-icon>
                        </span>
                        <span class="has-text-weight-medium">Поблагодарить</span>
                     </router-link>
                  </span>
                  <span class="navbar-item">
                     <button class="button is-primary is-inverted is-uppercase" @click="renderModal">
                        <span class="icon">
                           <svg-icon type="mdi" :path="path.cash"></svg-icon>
                        </span>
                        <span class="has-text-weight-medium">Успешный год</span>
                     </button>
                  </span>
               </div>
            </div>
         </div>
      </header>
   </div>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon'
import { mdiCharity, mdiCashMultiple } from '@mdi/js'

   export default {
      data() {
         return {
            path: {
               charity: mdiCharity,
               cash: mdiCashMultiple
            },
            isActive: false
         }
      },
      methods: {
         renderModal() {
            this.$emit('modal')
         }
      },
      components: {
         SvgIcon
      }
   }
</script>

<style lang="scss" scoped>
.hero .navbar {
       background: #31C5A1;
}
</style>