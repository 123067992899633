<template>
   <div class="modal is-active">

      <div class="modal-background" @click="closeModal"></div>
      <button
         class="modal-close is-large"
         aria-label="close"
         @click="closeModal"
      ></button>

      <keep-alive>
         <component :is="componentName"></component>
      </keep-alive>

   </div>
</template>

<script>
import TheAuthor from './modals/TheAuthor'
import TheBath1 from './modals/TheBath1'

export default {
   inject: ['changeModalVisibility'],
   props: ['type'],
   mounted() {
      console.log('Modal done...')
   },
   methods: {
      closeModal() {
         this.changeModalVisibility()
      }
   },
   computed: {
      componentName() {
         return 'the-' + this.type
      }
   },
   components: {
      TheAuthor,
      TheBath1
   }
}
</script>

<style lang="scss" scoped>
   @media screen and (min-width: 769px) {
      .modal-content, .modal-card {
         width: 100%;
         max-width: 900px;
      }
   }
</style>