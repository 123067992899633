<template>
   <progress class="progress is-primary is-small" max="100" :value="(index + 1) / data.length * 100"></progress>

   <div class="tile is-ancestor mt-5">
      <div class="tile is-parent">
         <article class="tile is-child notification has-background-info-light has-text-link">
            <p class="title is-size-4 is-size-6-touch">Формула Дохода</p>
         </article>
      </div>
      <div class="tile is-parent">
         <article class="tile is-child notification is-info">
            <p class="title is-size-4 is-size-6-touch"> {{ data[index].type }}</p>
         </article>
      </div>
      <div class="tile is-parent">
         <article class="tile is-child notification is-link">
            <p class="title is-size-4 is-size-6-touch">Твои Баллы: {{ this.result }}</p>
         </article>
      </div>
   </div>


   <div class="tile is-ancestor">
      <div class="tile is-parent is-8">
         <article class="tile is-child box is-flex is-flex-direction-column is-justify-content-space-between">
            <div class="block mb-3">
               <p class="title is-size-4 is-size-5-mobile">{{ data[index].title }}</p>
               <p class="subtitle is-size-6">{{ data[index].subTitle }}</p>
            </div>

            <div class="message is-primary">
               <div class="message-body">
                  {{ data[index].description }}
               </div>
            </div>

            <div class="field is-grouped is-flex-desktop-only" v-if="!isFinish">
               <p class="control">
                  <div class="buttons has-addons are-large">
                     <button
                        class="button is-primary"
                        :disabled="score === 0"
                        @click="score--"
                     >
                        <span class="icon">
                           <svg-icon type="mdi" :path="path.minus"></svg-icon>
                        </span>
                     </button>
                     <div class="button is-primary is-outlined is-static has-text-weight-semibold">
                        {{ score }}
                     </div>
                     <button
                        class="button is-primary"
                        :disabled="score === data[index].maxScore"
                        @click="score++"
                     >
                        <span class="icon">
                           <svg-icon type="mdi" :path="path.plus"></svg-icon>
                        </span>
                     </button>
                  </div>
               </p>
               
               <p class="control pl-6">
                  <button
                     v-if="index !== data.length"
                     class="button is-link is-large"
                     :disabled="score === 0"
                     @click="nextStep"
                  >
                     <span>{{ (index === data.length - 1) ? 'Завершить Формулу' : 'Следующий Вопрос' }}</span>
                     <span class="icon">
                        <svg-icon type="mdi" :path="path.check"></svg-icon>
                     </span>
                  </button>
               </p>
            </div>

            <div class="field" v-else>
               <button     
                  class="button is-link is-large"
                  @click="getResult"
               >
                  <span>Узнать Результаты</span>
                  <span class="icon">
                     <svg-icon type="mdi" :path="path.check"></svg-icon>
                  </span>
               </button>
            </div>  
         </article>
      </div>

      <div class="tile is-parent">
         <article class="tile is-child box">
            <p class="title is-size-4">{{ data[index].name }}</p>
            <figure class="image is-4by3">
               <img :src="getImg">
            </figure>
         </article>
      </div>
   </div>

</template>

<script>

import SvgIcon from '@jamescoyle/vue-icon'
import { mdiMinus, mdiPlus, mdiCheckAll } from '@mdi/js'

export default {
   inject: ['formulaDB', 'renderResult'],
   data() {
      return {
         data: this.formulaDB,
         index: 0,
         score: 0,
         result: 0,
         path: {
            minus: mdiMinus,
            plus: mdiPlus,
            check: mdiCheckAll
         },
         matrix: [],
         isFinish: false
      }
   },
   methods: {
      nextStep() {
         this.matrix.push(this.score)
         this.result += this.score
         this.score = 0
         if (this.index === this.data.length - 1) {
            this.isFinish = true
            return
         }
         this.index++
      },
      getResult() {
         this.renderResult(this.matrix)
         this.$router.push('/result')
      }
   },
   mounted() {
      document.title = 'Диагностика Финансов | TigrisCo'
   },
   computed: {
      getImg() {
         const imgName = this.data[this.index].img.toLowerCase()
         return require(`../assets/images/${imgName}.jpg`)
      }
   },
   components: {
      SvgIcon
   }
}
</script>

<style lang="scss" scoped>
@import '~bulma/sass/utilities/mixins';

.notification {
   padding: 0.85rem 1.5rem;
}
.message-body {
   font-size: 1.1rem;
}

@include touch {
  .field.is-grouped {
     flex-direction: column;
     align-items: center;

     .control:last-child {
        padding-top: 20px;
     }

     .pl-6 {
        padding-left: 0 !important;
     }
  }
}

@include mobile {
   .hero-body {
      padding: 1rem;

      .progress {
         margin-bottom: 0.25rem;
      }

      .mt-5 {
         margin-top: 0 !important;
      }

      .notification {
         padding: 0.5rem;
         text-align: center;
      }

      .message-body {
         font-size: 0.85rem;
      }

   }


}
</style>