import { createRouter, createWebHistory } from 'vue-router'

import Home from './views/Home'
import Start from './views/Start'
import Result from './views/Result'
import Promo from './views/Promo'
import Tips from './views/Tips'
import Gratitude from './views/Gratitude'

export default createRouter({
   history: createWebHistory(),
   routes: [
      { path: '/', component: Home },
      { path: '/start', component: Start  },
      { path: '/result', component: Result },
      { path: '/promo', component: Promo },
      { path: '/tips', component: Tips },
      { path: '/gratitude', component: Gratitude }
   ],
   linkActiveClass: 'is-active'
})